import React, { useState, useEffect } from "react";
import "./cpSetts.css";
import axios from "axios";
import { NavLink, Link } from "react-router-dom";

const CorpPageSettings = () => {
  const [hpbanners, setHpBanners] = useState([]);

  useEffect(() => {
    axios
      .get("https://nh-backend-nu.vercel.app/api/v1/admin/settings/corporate")
      .then((response) => {
        console.log("API Response:", response.data);

        if (response.data && Array.isArray(response.data.contents)) {
          setHpBanners(response.data.contents);
        } else {
          console.error("Invalid response format. Expected an array.");
        }
      })
      .catch((error) => {
        console.error("Error fetching content:", error.message);
      });
  }, []);

  return (
    <div>
      <div className="cpsetheader">
        <h1>Contents</h1>
        <NavLink to="../add-corporate-image">
          <button>Add New Content</button>
        </NavLink>
      </div>
      <div className="cpsetbody">
        {Array.isArray(hpbanners) &&
          hpbanners.map((content, index) => (
            <Link key={index} to={`../edit-corp-image/${content._id}`}>
              <div key={index} className="CpbannerContentItem">
                <img src={content.image} alt={`Content ${index}`} />
                <p>{content.shortDescription}</p>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default CorpPageSettings;
