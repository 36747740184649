import React, { useState, useEffect } from "react";
import "./catalog.css";
import { Link } from "react-router-dom";

const Catalog = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  const api_url = "https://nh-backend-nu.vercel.app/api/v1/corporate/courses";

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(api_url);
        const data = await response.json();

        if (response.ok) {
          // Randomize the data and take only 8 unique courses
          const randomCourses = data
            .sort(() => Math.random() - 0.5) // Shuffle array
            .slice(0, 8); // Take the first 8

          setCourses(randomCourses);
        } else {
          console.error("Error fetching courses:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      } finally {
        setLoading(false); // Always turn off loading after fetching
      }
    };

    fetchCourses();
  }, []);
  return (
    <div className="catalogContainer">
      <div className="catalogshell">
        <div className="catTop">
          <h1 className="topheading">
            <span>Our Course Catalog</span>
          </h1>
          <Link to="/Corporate-Courses">
            <p className="midheading">
              <span>All Courses</span>
            </p>
          </Link>
        </div>
        <div className="courseContainer">
          {loading ? (
            <p>Loading...</p>
          ) : (
            courses.map((item, index) => (
              <Link key={index} to={`/corporate-courses/${item._id}`}>
                <div key={index} className="courseList">
                  <img
                    src={item.courseImage}
                    alt={item.courseTitle}
                    className="courseImg"
                  />
                  <div className="courseDiv">
                    <p>{item.courseTitle}</p>
                  </div>
                </div>
              </Link>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Catalog;
