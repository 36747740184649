import React from "react";
import "./school.css";
import Navbar from "../../components/navbar/navbar";
import Banner from "../../components/banner/Banner";
import imgSummer from "../../assets/images/div.elementskit-border-divider.png";
import imgHub from "../../assets/images/line steeze.png";
import jetImg from "../../assets/images/zooooom.png";
import Testimonials from "../../components/studentswords/studentWords";
import Subscribe from "../../components/wf/subscribe";
import Footer from "../../components/footer/footer";
import Enquiries from "../../components/schoolenquiries/schoolenquiries";
import TABS from "../../components/schooltabs/schooltabs";

const school = () => {
  return (
    <div>
      <Navbar />
      <Banner />
      <TABS />
      <div className="techXtra">
        <div className="techXtra_info">
          <h2>Kids TechXtra</h2>
          <h6>
            Shaping Tomorrow’s Innovators Today! Ignite Young Tech Wizards!
          </h6>
          <p>
            New Horizons Kids Tech Extra offers practical ICT skills for kids
            aged 4-18, teaching them to create games, apps, animations, and more
            after school and during weekends. Virtual classes are available on
            weekdays and weekends. Give your child a great start today
          </p>
          <button>Register Now</button>
        </div>
      </div>
      <div className="session">
        <h2>Virtual Live Training</h2>
        <div className="session-info">
          <div className="left-column">
            <p>
              As you may be aware that Tech Companies, and indeed all
              Organizations in the digital economy are coming to realize that IT
              Skills are vital for all Employers and Employees in this digital
              era. 
              <br />
              Furthermore, recent events in the world shows that IT skills are
              no longer optional but vital. The world is not as we know it
              anymore, everything has gone digital. It is of great importance we
              prepare our child(ren) for this change. As the more skilled they
              are, the easier it would be for them to be successful either as an
              employer or employee in the Future.
              <br />
              Therefore, New Horizons; the World Largest IT/E-Business Training
              and Solutions Organization has provided a medium through which
              your Child(ren) can start acquiring relevant IT skills from the
              comfort of your home.
              <br />
              As you may be aware that Tech Companies, and indeed all
              Organizations in the digital economy are coming to realize that IT
              Skills are vital for all Employers and Employees in this digital
              era
            </p>
          </div>

          <div className="right-column">
            <p>
              Ut aliquet tellus sit amet mauris accumsan, a hendrerit nunc
              tincidunt. Cras at viverra enim. Duis ut commodo libero. Nam
              tincidunt auctor nisi eget ultrices. Donec posuere vehicula
              sapien. Aliquam nec scelerisque libero, eget congue odio. Duis eu
              turpis ut ex fermentum lacinia. Mauris at lobortis lacus. Integer
              in malesuada purus, eu malesuada sapien.
            </p>
            <br />
            <button>Register Now</button>
          </div>
        </div>
      </div>
      <div className="summerTech">
        <img src={imgSummer} alt="" />
        <h1>Summer Tech Academy</h1>
        <p>
          The New Horizons Summer Tech Academy is over a decade long initiative
          aimed at equipping Children with high-end ICT skills. These
          Professional Technology skills empower them early in life for
          strategic positioning as the Nigerian-groomed future world-rated
          Technoprenuers like Jeff Bezos, Bill Gate, Mark Zuckerberg, Google
          guys etc.  who are among the top 10 richest people in the World.
          <br />
          This Academy run in batches between July and September annually during
          the Summer holiday of Schools at various New Horizons Training Centers
          across the Country and is targeted at Children between ages 5 and 19. 
          Different Courses like Robotics, 3D Modelling and Printing Technology,
          3D Animation, Mobile Application Development, Web Design, Multimedia,
          Different Programming Language, Computer Fundamentals etc. are taught
          to Pupils/Students with Project Based Learning approached.
        </p>
        <button>Register Now</button>
      </div>

      <div className="digitalHub">
        <div className="hubLeft">
          <img src={imgHub} alt="" />
          <h1>NextGen Digital Hub</h1>
          <p>
            NextGen Digital Hub is the latest education curriculum available for
            students to excel in their current academic fields and in
            preparation for a brighter future. It is the first of its kind in
            West Africa, which is specially designed for the empowerment of
            Nigerian kids.
            <br />
            This hub is suitable for children from ages 4-18 and would hold for
            8 weeks where students would be engaged in end-to-end skills
            empowerment sessions on:
            <br />
            NextGen Tech Academy is a Saturday-only ICT training programme
            designed to empower Children within the ages 5-19 with highly sorted
            after ICT Skills ranging from Robotics to various programming
            Languages and Graphics/3D Printing Technology.
          </p>
        </div>
        <div className="hubRight">
          <img src={jetImg} alt="" />
        </div>
      </div>

      <div className="summerTech">
        <h1>EZ Revolution Robots and Robot Parts Sales</h1>
        <p>
          Robots are programmable machines or gadgets that can replace humans in
          performing a range of tasks by executing input commands. As the world
          looks forward to a future of fully-fledged artificial intelligence,
          you can expect these gadgets will soon have even more advanced
          features.
          <br />
          Our sole partnership with EZ-Robots enables us to provide revolution
          Robots and Robot Parts which serves as a medium for Pupils and
          Students to be gain complete Robotic Literacy.
          <br />
          EZ-Robot is an edtech solution that supports teaching and inspires
          learning. Empowering lifelong learning through real world applications
          and problem-solving, EZ-Robot provides new opportunities for
          reinforcing the power of science, technology, engineering, arts, and
          mathematics (STEAM).
          <br />
          The comprehensive and modular EZ-Robot technology platform grows with
          students as they learn. EZRobot software and hardware innovations
          provide approachable methods for learning complex robotics skills.
          From the basics of coding to machine learning, students graduate from
          beginner to advanced users as they gain the skills and knowledge
          needed to thrive in future STEAM related careers.
          <br />
        </p>
      </div>
      <div className="achievements">
        <h2>Some of our Achievements</h2>
        <div className="points achievepoints">
          <div className="points1">
            <ul className="achievements-list">
              <li>
                Pioneered Integration of International IT/E- Business
                Project/Certification based Hands-on Training into Academic
                Curricula of Primary Schools, Secondary Schools and Universities
                in Nigeria.
              </li>
              <li>We have produced the youngest SECURITY 5 Professional</li>
              <li>
                Constantly Achieve 95% Minimum International Exams Pass rate by
                students our partner Secondary and primary schools.
              </li>
              <li>
                We have produced Africa’s youngest Microsoft Office Specialist
              </li>
            </ul>
          </div>
          <div className="points points2">
            <ul className="achievements-list">
              <li>
                Produced Africa’s Champion in Technovation Competition (2013,
                2019 & 2020).
              </li>
              <li>
                Tomisin Ogunnubi at age 12 Developed My Locator App in 2016
                Which won her UK Scholarship 2017 and US Scholarship in 2018.
              </li>
              <li>
                9yrs old, Davidson Oseremen and Shitu Rilwan from Doregos
                Private Academy coupled computer system named “dash” within 5
                minutes.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Testimonials />
      <Enquiries />
      <Subscribe />
      <Footer />
    </div>
  );
};

export default school;
