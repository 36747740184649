import React, { useState, useEffect } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import "./admin.css";
import {
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { auth } from "../../../services/auth/uauth";
import AdminSidebar from "../Sidebar/adminSidebar";
import AdminNavbar from "../Navbar/adminNavbar";

const AdminPage = () => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  //   switch (activeComponent) {
  //     case "newCourse":
  //       return {
  //         main: "New Course",
  //         sub: "Add New Course",
  //       };
  //     case "course":
  //       return {
  //         main: "Courses",
  //         sub: "Courses",
  //       };
  //     case "regData":
  //       return {
  //         main: "Registration",
  //         sub: "Data",
  //       };
  //     case "dashboard":
  //       return {
  //         main: "Dashboard",
  //         sub: "Dashboard",
  //       };
  //     case "homepageSettings":
  //       return {
  //         main: "Settings",
  //         sub: "Home Banner",
  //       };
  //     case "addnewcontents":
  //       return {
  //         main: "Settings",
  //         sub: "Edit Home Pictures",
  //       };
  //     case "corporateSettings":
  //       return {
  //         main: "Settings",
  //         sub: "Corporate Banner",
  //       };
  //     case "corporateImgSettings":
  //       return {
  //         main: " Corp Settings",
  //         sub: "Edit Corporate Images",
  //       };
  //     case "retailSettings":
  //       return {
  //         main: "Settings",
  //         sub: "Retail Banner",
  //       };
  //     case "retailImgSettings":
  //       return {
  //         main: "Settings",
  //         sub: "Edit Retail Images",
  //       };
  //     default:
  //       return {
  //         main: "",
  //         sub: "",
  //       };
  //   }
  // };

  const getPageContent = () => {
    switch (pathname) {
      case "/admin-Dashboard":
        return {
          pageTitle: "Dashboard",
          pageDescription: "Dashboard",
        };
      case "/admin-Dashboard/reg-data":
        return { pageTitle: "Registration", pageDescription: "Data" };
      case "/admin-Dashboard/view-courses":
        return { pageTitle: "Courses", pageDescription: "Courses" };
      case "/admin-Dashboard/add-new-course":
        return { pageTitle: "New Course", pageDescription: "Add New Course" };
      case "/admin-Dashboard/homesettings":
        return { pageTitle: "Settings", pageDescription: "Home Banner" };
      case "/admin-Dashboard/corpbannersettings":
        return { pageTitle: "Settings", pageDescription: "Corporate Banner" };
      case "/admin-Dashboard/retailbannersettings":
        return { pageTitle: "Settings", pageDescription: "Retail Banner" };
      default:
        return { pageTitle: "Dashboard", pageDescription: "Dashboard" };
    }
  };

  const { pageTitle, pageDescription } = getPageContent();

  // const renderContent = () => {
  //   switch (activeComponent) {
  //     case "addnewcontents":
  //       return (
  //         <AddContentImg
  //           onSettingsClick={() => handleNewContentClick("homepageSettings")}
  //         />
  //       );
  //     case "corporateSettings":
  //       return (
  //         <CpContentImg
  //           onSettingsClick={() => handleNewContentClick("corporateSettings")}
  //         />
  //       );
  //     default:
  //       return null;
  //   }
  // };

  // const headingContent = getHeadingContent();

  useEffect(() => {
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        console.log("Persistence set");
      })
      .catch((error) => {
        console.log("Error setting persistence:", error.message);
      });
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserLoggedIn(true);
      } else {
        setUserLoggedIn(false);
        navigate("/user/admin001");
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  return (
    <>
      {userLoggedIn && (
        <div className="adminContainer">
          <AdminNavbar
            pageTitle={pageTitle}
            pageDescription={pageDescription}
          />

          <div className="adminpageContents">
            <AdminSidebar />
            <div className="outlethusk">
              <Outlet />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminPage;
