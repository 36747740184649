import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import logo from "../../assets/logo/Layer_1.svg";
import { FaTwitter, FaLinkedin, FaInstagram, FaFacebook } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footerContainer">
        <div className="footerTop">
          <div className="footerContents">
            <div className="footerLogo">
              <img src={logo} alt="New Horizons Logo" className="newHor" />
            </div>
            <div className="footerCol1">
              <h1>Company</h1>
              <div className="col1menu">
                <li>
                  <Link to="/" className="linkTxt">
                    <span>Home</span>
                  </Link>
                </li>
                <li>
                  <span>About Us</span>
                </li>
                <li>
                  <Link to="/contactus" className="linkTxt">
                    <span>Contact Us</span>
                  </Link>
                </li>
                <Link to="/alumni" className="linkTxt">
                  <li>
                    <span>Alumni</span>
                  </li>
                </Link>
              </div>
            </div>
            <div className="footerCol2">
              <h1>Trainings</h1>
              <div className="col2menu">
                <li>
                  <Link to="/corporate" className="linkTxt">
                    <span>Corporate</span>
                  </Link>
                </li>
                <li>
                  <Link to="/retail" className="linkTxt">
                    <span>Individuals</span>
                  </Link>
                </li>
                <li>
                  <a
                    href="https://virtuallive.newhorizonsnigeria.com.ng/"
                    target="_blank"
                    rel="noreferrer"
                    className="linkTxt"
                  >
                    <span>VirtualLive</span>
                  </a>
                </li>
                <li>
                  <Link to="/school" className="linkTxt">
                    <span>School Hub</span>
                  </Link>
                </li>
              </div>
            </div>
            <div className="footerCol3">
              <h1>Contact Us</h1>
              <div className="col3menu">
                <li className="ct1">
                  <a
                    href="https://web.facebook.com/nhnigeria"
                    target="_blank"
                    rel="noreferrer"
                    className="alnk  linkTxt"
                  >
                    <FaFacebook />
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="ct2">
                  <a
                    href="https://twitter.com/nhnigeria"
                    target="_blank"
                    rel="noreferrer"
                    className="alnk  linkTxt"
                  >
                    <FaTwitter />
                    <span>Twitter</span>
                  </a>
                </li>
                <li className="ct3">
                  <a
                    href="https://www.linkedin.com/company/nhnigeria/"
                    target="_blank"
                    rel="noreferrer"
                    className="alnk  linkTxt"
                  >
                    <FaLinkedin />
                    <span>LinkedIn</span>
                  </a>
                </li>
                <li className="ct4">
                  <a
                    href="https://www.instagram.com/nhnigeria/"
                    target="_blank"
                    rel="noreferrer"
                    className="alnk  linkTxt"
                  >
                    <FaInstagram />
                    <span>Instagram</span>
                  </a>
                </li>
              </div>
            </div>
          </div>
        </div>
        <div className="footerBottom">
          <div className="fbContents">
            <p>© 2024 New Horizons Nigeria</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
