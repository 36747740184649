import React, { useState, useEffect } from "react";
import "./retailCatalog.css";
import { Link } from "react-router-dom";

const RetailCatalog = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  const api_url = "https://nh-backend-nu.vercel.app/api/v1/retail/courses";

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(api_url);
        const data = await response.json();

        if (response.ok) {
          // Randomize the data and take only 8 unique courses
          const randomCourses = data
            .sort(() => Math.random() - 0.5) // Shuffle array
            .slice(0, 8); // Take the first 8

          setCourses(randomCourses);
        } else {
          console.error("Error fetching courses:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  return (
    <div className="ret-catalogContainer">
      <div className="ret-catalogshell">
        <div className="ret-catTop">
          <h1 className="ret-topheading">
            <span>Our Course Catalog</span>
          </h1>
          <Link to="/retailcourses">
            <p className="ret-midheading">
              <span>All Courses</span>
            </p>
          </Link>
        </div>
        <div className="ret-courseContainer">
          {loading ? (
            <p>Loading...</p>
          ) : (
            courses.map((item, index) => (
              <Link key={index} to={`/retail-courses/${item._id}`}>
                <div key={index} className="ret-courseList">
                  <img
                    src={item.courseImage}
                    alt={item.courseTitle}
                    className="ret-courseImg"
                  />
                  <div className="ret-courseDiv">
                    <p>{item.courseTitle}</p>
                  </div>
                </div>
              </Link>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default RetailCatalog;
