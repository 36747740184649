import React from "react";
import "./students.css";

const Testimonials = () => {
  return (
    <div className="testimonial">
      <div className="testContents">
        <div className="testTop">
          <h1>What Our Students are saying</h1>
          <div className="testTopSub">
            <h1>
              Over <span className="fiftyk">50000+</span> Students in 12+
              Centers choose NewHorizons
            </h1>
          </div>
        </div>
        <div className="testBottom">
          <div className="bottom1">
            <div className="bottom1_in">
              <p>
                “This is to testify that the training was conducted in good
                ethical manner, the hospitality of the host was wonderful. Lynda
                the Logistic officer did a good job in making us comfortable.
                Generally, the training is satisfactory.”
              </p>
            </div>
            <div className="bottom1_in2">
              <p>Akinloye Okinbaloye</p>
              <p>Student</p>
            </div>
          </div>
          <div className="bottom2">
            <iframe
              title="YouTube Video"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/qduXqkZLFJg?mute=1&controls=0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
              allowFullScreen
            ></iframe>
          </div>
          <div className="bottom3">
            <div className="bottom3inside">
              <p>
                The training was an eye opener on the vulnerability and inherent
                risk we run as an institution. The training has empowered me to
                effectively mitigate the noted flaws in all platforms and block
                all loop holes that can be exploited by hackers.
              </p>
            </div>
            <div className="bottom3inside2">
              <p>Christian Onyenbeji</p>
              <p>Graduate</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
