import React, { useState } from "react";
import "./adminSidebar.css";
import dashlogo from "../../../assets/logo/dashboard.svg";
import courselogo from "../../../assets/logo/courses.svg";
import datalogo from "../../../assets/logo/data.svg";
import { Link } from "react-router-dom";

const AdminSidebar = () => {
  const [activePage, setActivePage] = useState("dashboard");

  const handlePageClick = (page) => {
    setActivePage(page);
  };

  return (
    <div className="adminSidebar">
      <div className="sidebar2">
        <Link to="/admin-Dashboard/add-new-course">
          <button
            className={`sidebarButton ${
              activePage === "add-new-course" ? "active" : ""
            }`}
            onClick={() => handlePageClick("add-new-course")}
          >
            <span>Add New Course</span>
          </button>
        </Link>
        <Link to="/admin-Dashboard">
          <div
            className={`divDash ${
              activePage === "admin-Dashboard" ? "active" : ""
            }`}
            onClick={() => handlePageClick("admin-Dashboard")}
          >
            <img src={dashlogo} alt="" className="dash" />
            <span>Dashboard</span>
          </div>
        </Link>
        <Link to="/admin-Dashboard/view-courses">
          <div
            className={`divCourse ${
              activePage === "view-courses" ? "active" : ""
            }`}
            onClick={() => handlePageClick("view-courses")}
          >
            <img src={courselogo} alt="" className="courselogo" />
            <span>Courses</span>
          </div>
        </Link>
        <Link to="/admin-Dashboard/reg-data">
          <div
            className={`divCourse ${activePage === "reg-data" ? "active" : ""}`}
            onClick={() => handlePageClick("reg-data")}
          >
            <img src={datalogo} alt="" className="datalogo" />
            <span>Registration Data</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AdminSidebar;
