import React, { useState } from "react";
import axios from "axios";
import "./contactbody.css";
import { FaTwitter, FaLinkedin, FaInstagram, FaFacebook } from "react-icons/fa";
import location from "../../assets/logo/addy.svg";
import phone from "../../assets/logo/phone.svg";
import whatsapp from "../../assets/logo/whatsapp.svg";
import { toast } from "react-toastify";

const Contactbody = () => {
  const [contactForm, setContactForm] = useState({
    fullName: "",
    email: "",
    number: "",
    comments: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        "https://api.sheetmonkey.io/form/j4PyW7Wb6zdfT4vd5pLZ4T",
        contactForm
      )
      .then((response) => {
        toast.success("Message successfully sent!");
        setContactForm({ fullName: "", email: "", number: "", comments: "" });
      })
      .catch((error) => {
        toast.error("Error submitting form");
      });
  };

  return (
    <section id="contentwrap">
      <div id="contents">
        <div id="part1">
          <div id="contactdetails">
            <h1>
              <span>Contact Us</span>
            </h1>
            <ul>
              <li>
                <img src={location} alt="location logo" />
                <p>No 5 Babatola Street, Ikeja, Lagos</p>
              </li>
              <li>
                <img src={phone} alt="phone logo" />
                <p>+014537628</p>
              </li>
              <li>
                <img src={phone} alt="phone logo" />
                <p>+014537632</p>
              </li>
              <li>
                <img src={phone} alt="phone logo" />
                <p>+014537637</p>
              </li>
              <li>
                <img src={whatsapp} alt="location logo" />
                <p>08125541750</p>
              </li>
            </ul>
            <div id="enquiryQuestions">
              <p>Want to make an Enquiry?</p>
              <p>Kindly Fill the Form</p>
            </div>
            <div id="locateUs">
              <h3 className="locateHeading">
                <p>Find Us</p>
                {/* <hr className="contactbreakline" /> */}
              </h3>
            </div>
            <div className="locateMap">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15853.582981188902!2d3.3397774!3d6.5976399!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b9227eaba7d01%3A0x1e3c5e3bf322231b!2sNew%20Horizons%20Nigeria!5e0!3m2!1sen!2sng!4v1708348907190!5m2!1sen!2sng"
                title="Location Map"
                width="493"
                height="180"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
            <div className="followUs">
              <h3 className="followHeading">
                <p>Follow Us</p>
                {/* <hr className="contactbreakline2" /> */}
              </h3>
            </div>
            <div className="social-media-list">
              <ul className="sm-inner-list">
                <li>
                  <a
                    href="https://twitter.com/nhnigeria"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter className="contact-social" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/nhnigeria"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin className="contact-social" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/nhnigeria/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram className="contact-social" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://web.facebook.com/nhnigeria"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook className="contact-social" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="part2">
          <div className="contactFormContainer">
            <div className="cformHeader">
              <h2>
                <span>Send us a message</span>
              </h2>
            </div>
            <form className="contact-form">
              <label htmlFor="fullName">Full Name:</label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={contactForm.fullName}
                onChange={(e) => {
                  setContactForm({
                    ...contactForm,
                    fullName: e.currentTarget.value,
                  });
                }}
                required
              />
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={contactForm.email}
                onChange={(e) => {
                  setContactForm({
                    ...contactForm,
                    email: e.currentTarget.value,
                  });
                }}
                required
              />
              <label htmlFor="phoneNumber">Phone Number:</label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={contactForm.number}
                onChange={(e) => {
                  setContactForm({
                    ...contactForm,
                    number: e.currentTarget.value,
                  });
                }}
                required
              />
              <label htmlFor="comments">Comments:</label>
              <textarea
                id="comments"
                name="comments"
                rows="4"
                value={contactForm.comments}
                onChange={(e) => {
                  setContactForm({
                    ...contactForm,
                    comments: e.currentTarget.value,
                  });
                }}
                required
              ></textarea>
              <button
                type="submit"
                onClick={handleSubmit}
                className="contactButton"
              >
                <span>Submit</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contactbody;
