import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import "./addContImg.css";

const AddContentImg = () => {
  const navigate = useNavigate();
  const [contentTitle, setContentTitle] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [image, setImage] = useState(null);
  const [link, setLink] = useState("");
  const [uploadStatus, setUploadStatus] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append("contentTitle", contentTitle);
      formData.append("shortDescription", shortDescription);
      formData.append("image", image);
      formData.append("link", link);

      const response = await axios.post(
        "https://nh-backend-nu.vercel.app/api/v1/admin/settings/homepage",
        formData
      );
      // Handle success, update state or show a success message
      console.log("Upload success:", response.data);
      setUploadStatus("Upload successful");
      setContentTitle("");
      setShortDescription("");
      setImage(null);
      setLink("");
    } catch (error) {
      // Handle error, show an error message
      console.error("Error uploading:", error.message);
      setUploadStatus("Upload failed");
    }
  };

  return (
    <div className="tophomesettingbutton">
      <Link to="../homesettings">
        <button className="topHomeButton">Back</button>
      </Link>
      <div className="homeImgContainer">
        <div className="homeImgLeft">
          <form className="homeinnerLeft" onSubmit={handleFormSubmit}>
            <label htmlFor="contentTitle">Content Title</label>
            <input
              type="text"
              id="contentTitle"
              placeholder="Introduction to Python"
              value={contentTitle}
              onChange={(e) => setContentTitle(e.target.value)}
              required
            />
            <label htmlFor="shortDescription">Short Description</label>
            <input
              type="text"
              id="shortDescription"
              placeholder="Introduction to Python"
              value={shortDescription}
              onChange={(e) => setShortDescription(e.target.value)}
              required
            />
            <label htmlFor="image">Image</label>
            <input
              type="file"
              id="image"
              accept=".jpg, .png"
              onChange={handleFileChange}
              required
            />
            <label htmlFor="link">Link</label>
            <input
              type="text"
              id="link"
              placeholder="https://example.com"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              required
            />
            <button type="submit" className="addHomeBannerButt">
              Upload Image
            </button>
          </form>
          {uploadStatus && <p>{uploadStatus}</p>}
        </div>
        <div className="homeImgRight">
          {image && (
            <img
              src={URL.createObjectURL(image)}
              alt=""
              className="homeBanImg"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddContentImg;
