import React from "react";
import errorImg from "../../assets/images/error.svg";
import "./error.css";

const error = () => {
  return (
    <div className="error">
      <img src={errorImg} alt="Error Img" />
      <h3>Lost in the Digital Wilderness of Knowledge</h3>
      <p>
        Oh dear! It appears you've wandered off the digital path of
        enlightenment and found yourself lost amidst the tangled branches of the
        digital wilderness. The course you sought seems to have vanished into
        the digital undergrowth.
      </p>
      <a className= "ourCourses" href="/#ourCourses">Check our courses</a>
      <a className="backHome" href="/">Return to Homepage</a>
    </div>
  );
};

export default error;
