import React from "react";
import "./testpartners.css";
import testlogo1 from "../../assets/images/kryterion.png";
import testlogo2 from "../../assets/images/psi.png";
import testlogo3 from "../../assets/images/certiport.png";
import testlogo4 from "../../assets/images/pearson-vue.png";

const TestPartners = () => {
  return (
    <section className="testpartner">
      <div className="testContainer">
        <div className="testPartHead">
          <h1>Official Test Partner</h1>
        </div>
        <div className="testPartlogos">
          <div className="testlogospartners">
            <img src={testlogo1} alt="kryterion logo" />
            <img src={testlogo2} alt="psi logo" />
            <img src={testlogo3} alt="certiport logo" />
            <img src={testlogo4} alt="pearson-vue logo" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestPartners;
