import React from "react";
import "./schimg.css";
import schImg from "../../assets/images/Hotline-webslider.jpg.png";

const SchImg = () => {
  return (
    <div className="schtopdawg">
      <div className="schcontainer">
        <img src={schImg} alt="banner" className="imgschimg" />
      </div>
    </div>
  );
};

export default SchImg;
