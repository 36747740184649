import React from "react";
import "./microsoftazure.css";
import mazure1 from "../../../assets/images/microazure/azure-fundamentals-600x600-1.png.png";
import mazure2 from "../../../assets/images/microazure/azure-security-engineer-associate600x600.png.png";
import forArr from "../../../assets/logo/forArr.svg";

const MicrosoftAzureComponent = () => {
  return (
    <section className="mazurehusk">
      <div className="mazurecontainer">
        <div className="firstmrow">
          <div>
            <img src={mazure1} alt="" />
            <img src={mazure2} alt="" />
          </div>
          <div>
            <img src={mazure1} alt="" />
            <img src={mazure2} alt="" />
          </div>
          <div>
            <img src={mazure1} alt="" />
            <img src={mazure2} alt="" />
          </div>
          <div>
            <img src={mazure1} alt="" />
            <img src={mazure2} alt="" />
          </div>
        </div>
        <div className="secondmrow">
          <h1>Microsoft Azure Certification</h1>
          <p>
            <span>
              The new role-based Azure certifications establish a learning path
            </span>
            <span>
              from the Azure Fundamentals level to the Associate level
            </span>
            <span>and then to the Expert level.</span>
            <span>Here are the List of Microsoft Azure Certifications :–</span>
          </p>
          <div className="mazurelist">
            <ul>
              <li>Azure Fundamentals</li>
              <li>Azure Adminstrator Associate</li>
              <li>Azure Developer Associate</li>
              <li>Azure Solutions Architect Expert</li>
              <li>Azure DevOps Engineer Expert</li>
              <li>Azure Security Engineer Associate</li>
              <li>Azure Data Engineer Associate</li>
              <li>Azure AI Engineer Associate</li>
              <li>Azure Data Scientist Associate</li>
              <li>Azure IoT Developer</li>
              <li>Azure Architect for SAP Workloads</li>
            </ul>
          </div>
          <button>
            <span>BOOK FOR EXAM/INQUIRY</span>
            <img src={forArr} alt="" />
          </button>
        </div>
      </div>
      <hr />
    </section>
  );
};

export default MicrosoftAzureComponent;
