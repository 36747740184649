import React from "react";
import Firstcomponent from "./innerComponents/firstcomponent";
import ComponentTitle from "./innerComponents/componentTitle";
import Awscert from "./innerComponents/awscert";
import MicrosoftAzureComponent from "./innerComponents/microsoftazure";
import Comptia from "./innerComponents/comptia";
import Itilcert from "./innerComponents/itilcert";
import Cisco from "./innerComponents/cisco";
import Pmicert from "./innerComponents/pmicert";
import Mta from "./innerComponents/mta";

const TopComponent = () => {
  return (
    <div>
      <Firstcomponent />
      <ComponentTitle />
      <Awscert />
      <MicrosoftAzureComponent />
      <Comptia />
      <Itilcert />
      <Cisco />
      <Pmicert />
      <Mta />
    </div>
  );
};

export default TopComponent;
