import React, { useEffect } from "react";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import TopComponent from "../../components/blockCertifications/topComponent";
import TestPartners from "../../components/certifications/testpartners";
import SchImg from "../../components/schoolimage/schImg";

const Certification = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <SchImg />
      <TestPartners />
      <TopComponent />
      <Footer />
    </div>
  );
};

export default Certification;
