import React, { useEffect } from "react";
import "./alumni.css";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
import alum01 from "../../assets/images/alumni/alum01.jpeg";
import alum02 from "../../assets/images/alumni/alum02.jpeg";
import alum03 from "../../assets/images/alumni/alum03.jpeg";
import alum04 from "../../assets/images/alumni/alum04.jpeg";
import { FiArrowRightCircle } from "react-icons/fi";

const alumniLinks = [
  {
    name: "Ali",
    link: "https://www.youtube.com/embed/PtrieHl0ZuQ?si=_L3moXhlJxLptqll",
  },
  {
    name: "Mrs. Adie",
    link: "https://www.youtube.com/embed/rZ1vF6NGtn4?si=iCbrv6KLf6zxS8w2",
  },
  {
    name: "George",
    link: "https://www.youtube.com/embed/_u05mHe1NsQ?si=deLqF-ATCQk5iR7d",
  },
  {
    name: "Tracy",
    link: "https://www.youtube.com/embed/qduXqkZLFJg?si=7bJ8LjSKxjbH0dgn",
  },
  {
    name: "Emmanuel",
    link: "https://www.youtube.com/embed/xPsPPLIrB5o?si=qpek00sJGt0zudKy",
  },
];

const AlumniPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <div className="alumni">
        <div className="alumniTop">
          <h1 className="alumniTopHead">
            <span>Get Inspired</span>
          </h1>
          <h3 className="alumniTopP">
            <p>
              Meet Some of Our <span>Amazing</span> Alumni
            </p>
          </h3>
          <p className="sentence">
            See through the journeys of our outstanding alumni who have blazed
            trails, conquered challenges, and achieved remarkable success in
            various fields. Get ready to be inspired by their stories of
            determination, innovation, and excellence!
          </p>
        </div>
        <div className="alumniImages">
          <div className="person1">
            <img src={alum01} alt="Man in work" />
          </div>
          <div className="person2">
            <img src={alum02} alt="Man in work" />
          </div>
          <div className="person3">
            <img src={alum03} alt="Man in work" />
          </div>
          <div className="person4">
            <img src={alum04} alt="Man in work" />
          </div>
        </div>
        <div className="alumniAd">
          <div className="alumniAdTop">
            <p>
              Over <span>50000+</span> Students in 12+ Centers choose New
              Horizons
            </p>
          </div>
          <p className="alumniAdStatement">
            journeys of our outstanding alumni who have blazed trails, conquered
            challenges, and achieved remarkable success in various fields. Get
            ready to be inspired by their stories of determination, innovation,
            and excellence!
          </p>
          <button>
            <span>Register Now</span>
            <FiArrowRightCircle size={18} className="iconAlumni" />
          </button>
        </div>
        <section className="alumnibottom">
          <div className="videoTestimonials">
            <div className="videos">
              {alumniLinks.map((linkObj, index) => (
                <iframe
                  key={index}
                  src={linkObj.link}
                  title={`Video Testimonial ${index + 1}`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              ))}
            </div>
            <div className="alumniDetails">
              <h1>What our Amazing Alumni have to say</h1>
              <div className="alumniNames">
                <ul>
                  {alumniLinks.map((link, index) => (
                    <li key={index}>Meet {link.name}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default AlumniPage;
