import React, { useState, useEffect } from "react";
import "./blog.css";

const Blog = () => {
  const [blogData, setBlogData] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await fetch(
          "https://blog.newhorizonsnigeria.com.ng/wp-json/wp/v2/posts"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch blog data");
        }
        const data = await response.json();
        setBlogData(data);

        // Fetch image URLs for each post
        const urls = await Promise.all(
          data.slice(0, 4).map(async (item) => {
            const imageUrl = await fetchFeaturedMedia(item.featured_media);
            return imageUrl;
          })
        );
        setImageUrls(urls);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchBlogData();
  }, []);

  const fetchFeaturedMedia = async (mediaId) => {
    try {
      const response = await fetch(
        `https://blog.newhorizonsnigeria.com.ng/wp-json/wp/v2/media/${mediaId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch featured media");
      }
      const data = await response.json();
      return data.source_url;
    } catch (error) {
      console.error("Error fetching featured media:", error);
      return null;
    }
  };

  return (
    <div className="blog">
      <div className="blogContainer">
        <div className="blogTop">
          <h1>Blog</h1>
          <div className="blogTbottom">
            <p>Keep Up With the Tech Industry with NewHorizons</p>
          </div>
        </div>
        <div className="blogBottom">
          {blogData.slice(0, 4).map((item, index) => (
            <div key={index} className="bottom1B">
              <a href={item.link} target="_blank" rel="noreferrer">
                <h1 className="bottom1B-head">
                  <span>{item.title.rendered}</span>
                </h1>
                {imageUrls[index] && (
                  <img src={imageUrls[index]} alt="" className="blogimg1" />
                )}
                <div className="inbottom3">
                  <p>Tech</p>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;
