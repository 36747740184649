import React, { useState, useEffect } from "react";
import axios from "axios";
import "./editretailbanner.css";
import { useParams } from "react-router-dom";

const EditRetailBanner = () => {
  const { _id } = useParams();
  const [retailContent, setRetailContent] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);

  useEffect(() => {
    axios
      .get(
        `https://nh-backend-nu.vercel.app/api/v1/admin/settings/retail/${_id}`
      )
      .then((response) => {
        setRetailContent(response.data);
      })
      .catch((error) => {
        console.error("Error fetching content:", error.message);
      });
  }, [_id]);

  const handleChange = (e) => {
    setRetailContent({
      ...retailContent,
      [e.target.name]: e.target.value,
    });

    // Handle file input changes
    if (e.target.name === "image") {
      setImageFile(e.target.files[0]);
      setRetailContent({
        ...retailContent,
        image: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("contentTitle", retailContent.contentTitle);
      formData.append("shortDescription", retailContent.shortDescription);
      if (imageFile) {
        formData.append("image", imageFile);
      } else {
        formData.append("image", retailContent.image);
      }
      formData.append("link", retailContent.link);

      const response = axios.patch(
        `https://nh-backend-nu.vercel.app/api/v1/admin/settings/corporate/${_id}`,
        formData
      );
      console.log("Content updated:", response.data);
      setUploadStatus("Upload successful");
    } catch (error) {
      console.error("Error updating content:", error.message);
      setUploadStatus("Upload failed");
    }
  };

  if (!retailContent) {
    return <div>Loading...</div>;
  }

  return (
    <div className="editretailbannercontainer">
      <div className="editretailbannerhusk">
        <form
          action=""
          className="editretailbannerform"
          onSubmit={handleSubmit}
        >
          <div>
            <label htmlFor="contentTitle">Content Title</label>
            <input
              type="text"
              id="contentTitle"
              placeholder="Introduction to Python"
              value={retailContent.contentTitle}
              onChange={handleChange}
              name="contentTitle"
            />
          </div>
          <div>
            <label htmlFor="shortDescription">Short Description</label>
            <input
              type="text"
              id="shortDescription"
              placeholder="Introduction to Python"
              value={retailContent.shortDescription}
              onChange={handleChange}
              name="shortDescription"
            />
          </div>
          <div>
            <label htmlFor="image">Image</label>
            <input
              id="image"
              type="file"
              placeholder="Image URL"
              onChange={handleChange}
              name="image"
            />
          </div>
          <div>
            <label htmlFor="link">Link</label>
            <input
              type="text"
              id="link"
              placeholder="Link"
              value={retailContent.link}
              onChange={handleChange}
              name="link"
            />
          </div>
          <button type="submit">Edit Content</button>
        </form>
        <div className="editretailbannerright">
          <img
            src={retailContent.image}
            alt="Content"
            className="editretailrightImg"
          />
          {uploadStatus && <p>{uploadStatus}</p>}
          {/* <button>Delete Image</button> */}
        </div>
      </div>
    </div>
  );
};

export default EditRetailBanner;
