import React from "react";
import "./pmicert.css";
import forArr from "../../../assets/logo/forArr.svg";
import pmi1 from "../../../assets/images/pmicert/capm-600px.png.png";
import pmi2 from "../../../assets/images/pmicert/pmp-600px.png.png";

const Pmicert = () => {
  return (
    <section className="pmicerthusk">
      <div className="pmicertcontainer">
        <div className="pmicertwrites">
          <h1>PMI Certification</h1>
          <p>
            <span>
              In an increasingly projectized world, PMI professional
              certification ensures that you’re ready to meet the demands of
              projects and employers across the globe.{" "}
            </span>
            <span>
              Developed by practitioners for practitioners, our certifications
              are based on rigorous standards and ongoing research to meet the
              real-world needs of organizations. With a PMI certification behind
              your name, you can work in virtually any industry, anywhere in the
              world, and with any project management methodology.{" "}
            </span>
  
          </p>
          <button>
            <span>BOOK FOR EXAM/INQUIRY</span>
            <img src={forArr} alt="" />
          </button>
        </div>
        <div className="pmiimgs">
          <img src={pmi1} alt="" />
          <img src={pmi2} alt="" />
        </div>
      </div>
      <hr />
    </section>
  );
};

export default Pmicert;
