import React, { useState, useEffect } from "react";
import axios from "axios";
import "./banner.css";
import { Link } from "react-router-dom";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

const Banner = () => {
  const [contents, setContents] = useState([]);
  const [currentContentIndex, setCurrentContentIndex] = useState(0);

  useEffect(() => {
    axios
      .get("https://nh-backend-nu.vercel.app/api/v1/admin/settings/homepage")
      .then((response) => setContents(response.data.contents))
      .catch((error) => console.error("Error fetching contents:", error));

    // const scrollInterval = setInterval(() => {
    //   handleNextClick();
    // }, 10000);
    // // Clean up the interval on component unmount
    // return () => clearInterval(scrollInterval);
  }, []);

  const handlePrevClick = () => {
    setCurrentContentIndex((prevIndex) =>
      prevIndex === 0 ? contents.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentContentIndex((prevIndex) =>
      prevIndex === contents.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="banner">
      {contents.length > 0 && (
        <>
          <img
            src={contents[currentContentIndex]?.image}
            alt={contents[currentContentIndex]?.contentTitle}
            className="bannerImg"
          />
          <div className="bannerContent">
            <p className="bannerContentP">
              {contents[currentContentIndex]?.shortDescription}
            </p>
            <h1 className="bannerContenth1">
              {contents[currentContentIndex]?.contentTitle}
            </h1>
            <Link
              to={contents[currentContentIndex]?.link}
              className="bannerButtontop"
            >
              <button className="bannerButtontop">Learn More</button>
            </Link>
          </div>
          <div className="carouselBut">
            <p className="carouselButTxt">
              {contents[currentContentIndex]?.shortDescription}
            </p>
            <div className="carouselNav">
              <button onClick={handlePrevClick}>
                <MdOutlineArrowBackIos className="banBut" />
              </button>
              <button onClick={handleNextClick}>
                <MdOutlineArrowForwardIos className="banBut" />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Banner;
