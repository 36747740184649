import React, { useState, useEffect } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "./schooltabs.css";
import tabimg from "../../assets/images/tabbanner1.png";
import tab2img from "../../assets/images/opt2real.jpg";
import tab3img from "../../assets/images/opt2jpg.jpg";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [textAnimation, setTextAnimation] = useState(false);

  const tabsData = [
    {
      title: "Special training for Teacher",
      heading: "Microsoft Certified Educator (MCE)",
      image1: tab3img,
      text: "The Microsoft Certified Educator (MCE) exam is a competency-based professional development and certification offering. Technology-neutral, the MCE assessment enables educators to demonstrate competency as outlined in the United Nations Educational, Scientific, and Cultural Organization Information and Communications Technology, Competency Framework for Teachers (UNESCO ICT-CFT), Technology Literacy Approach. It also offers an opportunity for educators to differentiate themselves in the education field, and enhance their value for current and future employers. The Teaching with Technology curriculum is designed to help educators develop a deeper understanding of how ICT integration can enhance the teaching and learning experience, and enable students to acquire 21st century skills. Among many benefits, and align with the UNESCO ICT Competency Framework for Teachers. At the end of the training, Teachers writes Microsoft Certified Educators online examination and become Microsoft Certified Educator.",
    },
    {
      title: "ICT Training Partnership with Schools",
      heading: "Integrated Project-Based ICT Training Partnership with Schools",
      image1: tabimg,
      text: "As aptly agreed in a recently held New Technology in Education Summit, a Strategic Educational Institution must constantly rebrand in terms of alignment of its Educational Curriculum and training facilities with technological developments in the Global Educational arena. This is the only way School Brand can leverage cutting-edge technology to sustain and grow the enviable pedigree which Schools plan to build among the comity of top IT-driven Institutions in Nigeria. Under our Integrated Project-Based ICT Training, Pupils/Students of our partner Schools under our tutelage are exposed to various crucial Next Generation and modern IT skills such as Artificial Intelligence (AI), Robotics, Internet of Things (IoT), Machine Learning, 3D Printing Technology, 3D Animation, Mobile Application Development, Web Design, Multimedia, Different Programming Language etc. At the end of the training, Pupils/Students are guided to develop laudable projects in relation to the skill acquired. Furthermore, Students are exposed to relevant local and International Competitions in relation to the skill acquired. To get your School onboard our Integrated Project-Based ICT Training partnership, kindly contact us via phone call on 08122690403, 08122690445 or by send email to wasiuamoo@newhorizonsnigeria.com",
    },
    {
      title: "Learning Management System (LMS) for Schools",
      heading:
        "We develop and deploy Learning Management System (LMS) for Schools",
      image1: tab2img,
      text: "The outbreak of COVID-19 has revolutionized the Education Sector- forever. It will be recalled that during the Nation-Wide lock- down in China, where the virus first broke out, even though Schools were closed, but students never missed one day of Education and interaction as Education became virtual with most, if not all Schools over there. As it is in Nigeria today where Schools Academic activities is constantly being interrupted, so it is in Europe and America and other parts of the World. But the difference is that while the Students in those other Countries have full access to Virtual Education- less than 10% of Nigeria Schools has Virtual Technology to keep their Students productive during this uncertain period. This is exactly the PROBLEM we set out to solve for the Nigeria Education System- providing an all-encompassing, easy to use, interactive and pocket friendly Virtual Education Technology for Schools so that as Students are now at home- their Education will not stop.",
    },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveTab((prevTab) => (prevTab + 1) % tabsData.length);
      setTextAnimation(false);
    }, 60000);

    return () => clearInterval(intervalId);
  }, [tabsData.length]);

  useEffect(() => {
    setTextAnimation(true);
  }, [activeTab]);

  const handlePrevClick = () => {
    setActiveTab(
      (prevTab) => (prevTab - 1 + tabsData.length) % tabsData.length
    );
    setTextAnimation(false);
    setTimeout(() => setTextAnimation(true), 50);
  };

  const handleNextClick = () => {
    setActiveTab((prevTab) => (prevTab + 1) % tabsData.length);
    setTextAnimation(false);
    setTimeout(() => setTextAnimation(true), 50);
  };

  return (
    <div className="tabs">
      <div className="carousel">
        {tabsData.map((tab, index) => (
          <div
            key={index}
            className={`carousel-item ${index === activeTab ? "active" : ""}`}
          >
            <div className="content-details">
              <div className="content-banner">
                <img src={tab.image1} alt="Banner" />
              </div>
              <div
                className={`content-text ${
                  textAnimation ? "text-animate" : ""
                }`}
              >
                <h2>{tab.heading}</h2>
                <p>{tab.text}</p>
              </div>
            </div>
          </div>
        ))}
        <button className="carousel-control prev" onClick={handlePrevClick}>
          <FaArrowLeft />
        </button>
        <button className="carousel-control next" onClick={handleNextClick}>
          <FaArrowRight />
        </button>
      </div>
    </div>
  );
};

export default Tabs;
