import React, { useState } from "react";
import "./signup.css";
import { registerUser } from "../../services/auth/uauth";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignup = async () => {
    try {
      const user = await registerUser(email, password);
      console.log("User signed up:", user);
    } catch (error) {
      console.error("Signup failed:", error.message);
    }
  };

  return (
    <section className="signUpContainer">
      <div className="signUpFlask">
        <h1>Signup</h1>
        <div className="inputEnv">
          <div className="signUpMail">
            <label htmlFor="">Email</label>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="signUpToken">
            <label htmlFor="">Password</label>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="signUpDiv">
          <button onClick={handleSignup}>Signup</button>
        </div>
      </div>
    </section>
  );
};

export default Signup;
