import React, { useEffect } from "react";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";
import Subscribe from "../../components/wf/subscribe";
import Testimonials from "../../components/studentswords/studentWords";
import Blog from "../../components/blog/Blog";
import Trainings from "../../components/trainings/trainings";
import Partners from "../../components/partners/partners";
import Banner from "../../components/banner/Banner";

const Homepg = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <Banner />
      <div id="ourCourses">
        <Trainings />
      </div>
      <Partners />
      <Testimonials />
      <Blog />
      <Subscribe />
      <Footer />
    </div>
  );
};

export default Homepg;
