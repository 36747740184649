import "./App.css";
import Corporate from "./pages/Corporate/corporate";
import Homepg from "./pages/HomePage/homepg";
import { Routes, Route } from "react-router-dom";
import Retail from "./pages/Retail/retail";
import Tetiary from "./pages/Tetiary/tetiary";
import Contact from "./pages/Contact/contact";
import UserLogin from "./components/login/UserLogin";
import Signup from "./components/login/SignUp";
import AdminPage from "./pages/admin/page/adminpage";
import Certification from "./pages/Certification/Certification";
import Error from "./pages/error/error";
import Corpcourses from "./components/corporatecourses/corpcourses";
import AdminDashboard from "./pages/admin/Dashboard/Dashboard";
import AddCourse from "./pages/admin/addCourse/addCourse";
import RegTable from "./pages/admin/regData/regData";
import Courses from "./pages/admin/courses/courses";
import HomepgSet from "./components/homepgSettings/homepgSet";
import AddContentImg from "./components/addContentImg/addContentImg";
import CorpPageSettings from "./components/corporateSettings/corporateSettings";
import RetailSettings from "./components/retail/retailSettings";
import CpContentImg from "./components/addCpContentImg/cpContentImg";
import AddRetailImg from "./components/addRetImg/addRetailImg";
import EditHomePgset from "./components/edithomepgset/edithomepgset";
import EditCorpBanner from "./components/editcorpbanner/editcorpbanner";
import EditRetailBanner from "./components/editretailbanner/editretailbanner";
import School from "./pages/School/school";
import Retcourses from "./components/retailCourses/retailcourses";
import RetCourseDetail from "./components/retailindividualcourses/RetCourseDetail";
import CorpCourseDetail from "./components/corpindividualcourses/corpindividual";
import AlumniPage from "./components/alumni/alumni";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Homepg />} />
        <Route path="/corporate" element={<Corporate />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/retail" element={<Retail />} />
        <Route path="/alumni" element={<AlumniPage />} />
        <Route path="/retail-courses/:_id" element={<RetCourseDetail />} />
        <Route path="/corporate-courses/:_id" element={<CorpCourseDetail />} />
        <Route path="/tetiary" element={<Tetiary />} />
        <Route path="/Certification" element={<Certification />} />
        <Route path="/user/:admin001" element={<UserLogin />} />
        <Route path="/admin/signup" element={<Signup />} />
        <Route path="/Corporate-Courses" element={<Corpcourses />} />
        <Route path="/retailcourses" element={<Retcourses />} />
        <Route path="/admin-Dashboard" element={<AdminPage />} />
        <Route path="/school" element={<School />} />
        <Route path="*" element={<Error />} />
        <Route path="/admin-Dashboard" element={<AdminPage />}>
          <Route index element={<AdminDashboard />} />
          <Route
            path="add-new-course"
            element={<AddCourse isVisible={true} />}
          />
          <Route path="view-courses" element={<Courses />} />
          <Route path="reg-data" element={<RegTable />} />
          <Route path="homesettings" element={<HomepgSet />} />
          <Route path="corpbannersettings" element={<CorpPageSettings />} />
          <Route path="retailbannersettings" element={<RetailSettings />} />
          <Route path="add-home-image" element={<AddContentImg />} />
          <Route path="add-corporate-image" element={<CpContentImg />} />
          <Route path="add-retail-image" element={<AddRetailImg />} />
          <Route path="edit-home-image/:_id" element={<EditHomePgset />} />
          <Route path="edit-corp-image/:_id" element={<EditCorpBanner />} />
          <Route path="edit-retail-image/:_id" element={<EditRetailBanner />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
