import React from "react";
import "./partners.css";
import logo1 from "../partners/logos/First Bank of Nigeria svg.svg";
import logo2 from "../partners/logos/Interswitch_logo 1.svg";
import logo3 from "../partners/logos/MTN Nigeria svg.svg";
import logo4 from "../partners/logos/Page-1.svg";
import logo5 from "../partners/logos/veritas.svg";
import logo6 from "../partners/logos/firs.svg";

const Partners = () => {
  return (
    <div className="partShell">
      <div className="partnerContainer">
        <h1 className="partnerHead">
          <span>Our Partners</span>
        </h1>
        <div className="partnersLogos">
          <img src={logo1} alt="" />
          <img src={logo2} alt="" />
          <img src={logo3} alt="" />
          <img src={logo4} alt="" />
          <img src={logo5} alt="" />
          <img src={logo6} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Partners;
