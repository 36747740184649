import React, { useState, useEffect } from "react";
import "./retialcourses.css";
import axios from "axios";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";

const Retcourses = () => {
  const [corpPageCourses, setCorpPageCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [coursesPerPage] = useState(10);

  useEffect(() => {
    const fetchCorpCourseData = async () => {
      try {
        const response = await axios.get(
          "https://nh-backend-nu.vercel.app/api/v1/retail/courses"
        );
        setCorpPageCourses(response.data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchCorpCourseData();
  }, []);
  return (
    <div>
      <Navbar />
      <div className="retailCourses">
        <h1>Our Course Catalog</h1>
        <div className="coursesforret">
          {corpPageCourses.map((course, index) => (
            <Link key={index} to={`/retail-courses/${course._id}`}>
              <div key={course._id} className="coursedivisesret">
                <img src={course.courseImage} alt="" />
                <p>{course.courseTitle}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Retcourses;
