import React, { useEffect, useState } from "react";
import axios from "axios";
import "./cour.css";
import { IoFilterOutline } from "react-icons/io5";

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [coursesPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://nh-backend-nu.vercel.app/api/v1/corporate/courses"
        );
        setCourses(response.data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);

  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  const currentCourses = courses.slice(indexOfFirstCourse, indexOfLastCourse);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(courses.length / coursesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="courseTableContainer">
      <table>
        <thead className="courseTabHead">
          <tr>
            <th className="courseTabhead1">
              <span>Course title</span>
              <IoFilterOutline className="sortIcon" />
            </th>
            <th className="courseTabhead2">
              <span>Description</span> <IoFilterOutline className="sortIcon" />
            </th>
            <th className="courseTabhead3">
              <span>Duration</span> <IoFilterOutline className="sortIcon" />
            </th>
            <th className="courseTabhead4">
              <span>Link</span> <IoFilterOutline className="sortIcon" />
            </th>
            <th className="courseTabhead5">
              <span>Action</span> <IoFilterOutline className="sortIcon" />
            </th>
          </tr>
        </thead>
        <tbody>
          {currentCourses.map((course) => (
            <tr key={course._id}>
              <td className="courseTabbodyrow1">{course.courseTitle}</td>
              <td className="courseTabbodyrow2">
                {course.description.slice(0, 75)}...
              </td>
              <td className="courseTabbodyrow3">{course.duration}</td>
              <td className="courseTabbodyrow4">{course.link}</td>
              <td className="courseTabbodyrow5">{course.action}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={currentPage === number ? "active" : ""}
          >
            {number}
          </button>
        ))}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === Math.ceil(courses.length / coursesPerPage)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Courses;
