import React from "react";
import "./awscert.css";
import aws1 from "../../../assets/images/awscert/AWS-CloudPractitioner-2020.png.png";
import aws2 from "../../../assets/images/awscert/AWS-Certified_Solutions-Architect_Professional_512x512.c51482be8f98b8c9116fddc5918105cb12ecb280.png.png";
import aws3 from "../../../assets/images/awscert/database-specialty-badge_512x512.fd13ef247cdf74a0506ff6698aa218356022aab5.png.png";
import aws4 from "../../../assets/images/awscert/AWS-Developer-Associate-2020.png.png";
import aws5 from "../../../assets/images/awscert/AWS-Certified_Security_Specialty_512x512.bbc16dcfed17958fe1eb201fe236a9154b761c76.png.png";
import aws6 from "../../../assets/images/awscert/data-analytics-specialty-badge_512x512.f81954f771d19aebbeb21c337adadc9a79983c6e.png.png";
import aws7 from "../../../assets/images/awscert/AWS-SolArchitect-Associate-2020.png.png";
import aws8 from "../../../assets/images/awscert/AWS-Certified_Machine-Learning_Specialty_512x512.6ac490d15fe033a3d67ca544ecd0bcbcb10d391a.png.png";
import aws9 from "../../../assets/images/awscert/AWS-Certified_Sysops-Administrator_Associate_512x512.7ee4f9e7f4046349a3bfe27dcb1a54a340e04623.png.png";
import aws10 from "../../../assets/images/awscert/AWS-Certified_Advanced-Networking_Specialty_512x512.aed6ffb8112b76230b433a27efefe96eeab05684.png.png";
import forArr from "../../../assets/logo/forArr.svg";

const Awscert = () => {
  return (
    <section className="awstopdawg">
      <div className="awscerthusk">
        <div className="awsfirstrow">
          <h1 className="awsheading">Amazon Web Services Certifications</h1>
          <p>
            <span>
              The new role-based AWS certifications establish a learning path
              from
            </span>
            <span>
              the AWS Fundamentals level to the Associate level and then to the
            </span>
            <span>
              Professional level. Here are the List of AWS Certifications :–
            </span>
          </p>
          <button>
            <span>BOOK FOR EXAM/INQUIRY</span>
            <img src={forArr} alt="" />
          </button>
        </div>
        <div className="awssecondrow">
          <div>
            <img src={aws1} alt="" />
            <img src={aws2} alt="" />
            <img src={aws3} alt="" />
          </div>
          <div>
            <img src={aws4} alt="" />
            <img src={aws5} alt="" />
            <img src={aws6} alt="" />
          </div>
          <div>
            <img src={aws7} alt="" />
            <img src={aws8} alt="" />
          </div>
          <div>
            <img src={aws9} alt="" />
            <img src={aws10} alt="" />
          </div>
        </div>
      </div>
      <hr />
    </section>
  );
};

export default Awscert;
