import React, { useState, useEffect } from "react";
import "./trainings.css";
import { Link } from "react-router-dom";
import img01 from "../trainings/images/img01.jpg";
import virtualimg from "../trainings/images/virtualstii.jpg";
import retailimg from "../trainings/images/people-working-together-office-medium-shot.jpg";
import examimg from "../trainings/images/boy-studying-peacefully-library.jpg";
import schoolimg from "../trainings/images/school.jpg";

const optionsData = [
  {
    title: "Corporate Training",
    image: img01,
    width: 1026,
    height: 319,
    link: "/corporate",
    paragraph:
      "Corporate training unit has become a single source for all their training needs. Our customized enterprise solutions allow corporate training managers the ability to easily administer their learning programs and track the progress of all participating employees.",
  },
  {
    title: "Individual Training",
    image: retailimg,
    width: 1026,
    height: 319,
    link: "/retail",
    paragraph:
      "Training and development involves acquiring new skills or improving an individual’s skills set. Give yourself the IT / Business skills development through our training platform.",
  },
  {
    title: "Virtual Live Training",
    image: virtualimg,
    width: 1026,
    height: 319,
    link: "https://virtuallive.newhorizonsnigeria.com.ng/",
    paragraph:
      "Our virtual-live training services covers both the self-paced and virtual live training. This is available for individuals, corporate organizations and groups. We have flexible training schedule for your training needs any day, anytime, anywhere.",
  },
  {
    title: "Exam Centers",
    image: examimg,
    width: 1026,
    height: 319,
    link: "",
    paragraph:
      "We offer reliable and secure proctoring services on behalf of Professional & Vocational Boards.",
  },
  {
    title: "School Services",
    image: schoolimg,
    width: 1026,
    height: 319,
    link: "/school",
    paragraph:
      "The universe has indeed become one global village. Children and Schools in Nigeria will have no choice than to compete with the Chinese, Americans, the Koreans, the Jews, the Russians for the available resources in the global village to survive and thrive in the 21st century.",
  },
];

const Trainings = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const option = optionsData[currentIndex];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % optionsData.length);
    }, 10000);
    return () => clearInterval(intervalId);
  }, [option]);

  const currentOption = optionsData[currentIndex];

  return (
    <div className="trainingCover">
      <div className="trainingContainer">
        <div className="options-inner">
          <div className="options-list">
            {optionsData.map((option, index) => (
              <div
                key={index}
                className={`option ${
                  index === currentIndex % optionsData.length ? "active" : ""
                }`}
                onClick={() => setCurrentIndex(index)}
              >
                <h1 className="optionshead">{option.title}</h1>
              </div>
            ))}
          </div>
          <div className="content">
            <img
              src={currentOption.image}
              alt={currentOption.title}
              width={currentOption.width}
              height={currentOption.height}
              className="stylemage"
            />

            <div className="contentButtom">
              <div className="contentBottom1">
                <h2 className="content-heading">{currentOption.title}</h2>
                <p>{currentOption.paragraph}</p>
              </div>
              <Link to={currentOption.link}>
                <button className="learn">
                  <span>Learn More</span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trainings;
