import React, { useState, useEffect } from "react";
import "./userlog.css";
import { useParams, useNavigate } from "react-router-dom";
import { signInUser } from "../../services/auth/uauth";

const UserLogin = () => {
  const navigate = useNavigate();
  const { admin001 } = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);

  const handleSignIn = async () => {
    try {
      const user = await signInUser(email, password);
      alert("Welcome", user);
      setLoggedIn(true);
      navigate("/admin-Dashboard");
    } catch (error) {
      alert("Sign-in failed! Check credentials", error.message);
    }
  };

  useEffect(() => {
    const checkUserLogin = async () => {
      const userIsLoggedIn = true;
      if (!userIsLoggedIn) {
        setLoggedIn(true);
        navigate("/admin-Dashboard");
      }
    };

    checkUserLogin();
  }, [navigate]);

  return (
    <section className="admin-Husk">
      <div className="flaskContainer">
        {!loggedIn && (
          <>
            <h1>Admin Login</h1>
            <div className="formEnviron">
              <div className="adminUsername">
                <label htmlFor="">Username</label>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="adminUserToken">
                <label htmlFor="">Password</label>
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="buttondiv">
              <button onClick={handleSignIn}>Sign In</button>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default UserLogin;
