import React from "react";
import "./firstcomponent.css";
import examCentre from "../../../assets/images/exam-center.png";

const Firstcomponent = () => {
  return (
    <div className="fcomponentShell">
      <div className="fcomponentinnerhusk">
        <span className="innerfcomponentinnerhusk">
          <h3>
            <span>Our Services</span>
          </h3>
          <span className="testparacert">
            <p>
              At our various testing centers, New Horizons provides convenient
              locations,
              <br /> conducive environment and flexible delivery models.{" "}
            </p>
            <p>
              We have one of the most secured delivery systems in Nigeria, one
              that delivers tests every day of the week, any week of the year,
              in any of our individual testing centers. You can expect us to
              deliver customized services to meet all of your specific needs,
              including eligibility setting, scheduling and registration, test
              distribution and administration, test taker identity management,
              voucher distribution, and billing and payment.
            </p>
          </span>
          <button>SCHEDULE FOR EXAM</button>
        </span>
        <div className="imghusktest">
          <img src={examCentre} alt="Our world class exam center" />
        </div>
      </div>
    </div>
  );
};

export default Firstcomponent;
