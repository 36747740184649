import React from "react";
import "./itilcert.css";
import forArr from "../../../assets/logo/forArr.svg";
import itil1 from "../../../assets/images/itilcert/ITIL-4-Create-Deliver-and-Support.png.png";
import itil2 from "../../../assets/images/itilcert/ITIL_4_MANAGING_PROFESSIONAL.png.png";
import itil3 from "../../../assets/images/itilcert/ITIL_4_STRATEGIST_Direct_Plan_and_Improve-1.png.png";
import itil4 from "../../../assets/images/itilcert/ITIL_4_LEADER_Digital_and_IT_Strategy.png.png";
import itil5 from "../../../assets/images/itilcert/ITIL_4_STRATEGIC_LEADER.png.png";
import itil6 from "../../../assets/images/itilcert/ITIL_4_SPECIALIST_High_Velocity_IT__003_.png.png";

const Itilcert = () => {
  return (
    <section className="itilcontainer">
      <div className="itilcerthusk">
        <div className="itilcertimages">
          <div>
            <img src={itil1} alt="" />
            <img src={itil2} alt="" />
          </div>
          <div>
            <img src={itil3} alt="" />
            <img src={itil4} alt="" />
          </div>
          <div>
            <img src={itil5} alt="" />
          </div>
          <div>
            <img src={itil6} alt="" />
          </div>
        </div>
        <div className="itilcertwrite">
          <h1>ITIL Certifications</h1>
          <p>
            <span>
              ITIL 4 Foundation certification is designed as an introduction to
              ITIL 4 and enables candidates to look at IT service management
              through an end-to-end operating model for the creation, delivery
              and continual improvement of tech-enabled products and services.
            </span>
            <span>
              ITIL certifications demonstrate that professionals are educated in
              IT Service Management (ITSM) best practices. Aligning IT with the
              business can save time, reduce waste and help IT avoid costly
              rework. ITIL concepts are vendor-neutral and address the
              processes, terminology and methods used in modern IT.
            </span>
          </p>
          <button>
            <span>BOOK FOR EXAM/INQUIRY</span>
            <img src={forArr} alt="" />
          </button>
        </div>
      </div>
      <hr />
    </section>
  );
};

export default Itilcert;
