import React, { useEffect, useState } from "react";
import "./hpset.css";
import axios from "axios";
import { Link } from "react-router-dom";

const HomepgSet = () => {
  const [hpbanners, setHpBanners] = useState([]);

  useEffect(() => {
    axios
      .get("https://nh-backend-nu.vercel.app/api/v1/admin/settings/homepage")
      .then((response) => {
        console.log("API Response:", response.data);

        if (response.data && Array.isArray(response.data.contents)) {
          setHpBanners(response.data.contents);
        } else {
          console.error("Invalid response format. Expected an array.");
        }
      })
      .catch((error) => {
        console.error("Error fetching content:", error.message);
      });
  }, []);

  return (
    <div>
      <div className="hpsetheader">
        <h1>Contents</h1>
        <Link to="../add-home-image">
          <button className="addnewhomeimagebutton">Add New Content</button>
        </Link>
      </div>
      <div className="hpsetbody">
        {Array.isArray(hpbanners) &&
          hpbanners.map((content, index) => (
            <Link key={index} to={`../edit-home-image/${content._id}`}>
              <div className="bannerContentItem">
                <img src={content.image} alt={`Content ${index}`} />
                <p>{content.shortDescription}</p>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default HomepgSet;
