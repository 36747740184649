import React, { useEffect } from "react";
import Navbar from "../../components/navbar/navbar";
import Testimonials from "../../components/studentswords/studentWords";
import Footer from "../../components/footer/footer";
import RetailBanner from "../../components/retailbanner/retailbanner";
import RetailCatalog from "../../components/retailcatalog/retailCatalog";
import RFE from "../../components/retailenquiries/rFE";
import CoursesRetail from "../../components/coursesretail/coursesretail";

const Retail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <RetailBanner />
      <CoursesRetail />
      <RetailCatalog />
      <Testimonials />
      <RFE />
      <Footer />
    </div>
  );
};

export default Retail;
