import React, { useState } from "react";
import "./subscribe.css";
import sms from "../../assets/logo/sms.svg";

const Subscribe = () => {
  const [Mail, setMail] = useState("");

  const handleInputChange = (e) => {
    setMail(e.target.value);
  };

  const handleSubmit = () => {
    alert(`Subscribed!`);
    setMail("");
  };

  return (
    <div className="subscribe">
      <div className="contain">
        <div className="subscribe-contents">
          <div className="subTop">
            <div className="sub1">
              <img src={sms} alt="sms logo" className="smsTop" />
            </div>
            <div className="sub2">
              <h1>Stay in Touch</h1>
              <div className="sub2in">
                <h1>Get Latest Updates from NewHorizons</h1>
              </div>
            </div>
          </div>
          <div className="subBottom">
            <form action="">
              <input
                type="text"
                value={Mail}
                onChange={handleInputChange}
                className="subInput"
              />
            </form>
            <button className="formButton" onClick={handleSubmit}>
              <span>Learn More</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
