import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import homelogo from "../../../assets/logo/home.svg";
import brief from "../../../assets/logo/briefcase.svg";
import retSet from "../../../assets/logo/retSet.svg";
import teacher from "../../../assets/logo/teacher.svg";
import "./Dash.css";

const AdminDashboard = ({ handleContentClick, contentToEdit }) => {
  const [totalCourses, setTotalCourses] = useState("");
  const [corpCourses, setCorpCourses] = useState("");
  const [retailCourses, setRetailCourses] = useState("");

  useEffect(() => {
    const fetchTotalCourse = () => {
      axios
        .get("https://nh-backend-nu.vercel.app/api/v1/admin/totalcourses")
        .then((response) => {
          setTotalCourses(response.data);
        })
        .catch((error) => {
          console.error("Error fetching Courses:", error.message);
        });
    };
    const fetchCorpCourses = () => {
      axios
        .get("https://nh-backend-nu.vercel.app/api/v1/admin/corporatecourses")
        .then((response) => {
          setCorpCourses(response.data);
        })
        .catch((error) => {
          console.error("Error fetching Corporate Courses:", error.message);
        });
    };
    const fetchRetailCourses = () => {
      axios
        .get("https://nh-backend-nu.vercel.app/api/v1/admin/retailcourses")
        .then((response) => {
          setRetailCourses(response.data);
        })
        .catch((error) => {
          console.error("Error fetching Retail Courses:", error.message);
        });
    };
    fetchTotalCourse();
    fetchCorpCourses();
    fetchRetailCourses();
  }, []);

  return (
    <div>
      <div className="DashboardTop">
        <h1 className="DashHeading">Summary</h1>
        <div className="DashTopIn">
          <div className="DashInfo1">
            <h2>{totalCourses.total}</h2>
            <span>Courses</span>
          </div>
          <div className="DashInfo2">
            <h2>{corpCourses.numberOfCorporateCourses}</h2>
            <span>Corporate Courses</span>
          </div>
          <div className="DashInfo3">
            <h2>{retailCourses.numberOfRetailCourses}</h2>
            <span>Retail Courses</span>
          </div>
          <div className="DashInfo4">
            <h2>1504</h2>
            <span>Students</span>
          </div>
          <div className="DashInfo5">
            <h2>1504</h2>
            <span>Retail Students</span>
          </div>
          <div className="DashInfo6">
            <h2>1504</h2>
            <span>Corporate Students</span>
          </div>
        </div>
      </div>
      <div className="DashboardBottom">
        <h1>Settings</h1>
        <div className="DashBottomInfo">
          <NavLink to="./homesettings">
            <div className="DBInfo1">
              <div className="inDBInfo1">
                <span>
                  <img src={homelogo} alt="" className="dashhomelog" />
                </span>
              </div>
              <p>Homepage Settings</p>
            </div>
          </NavLink>
          <NavLink to="./corpbannersettings">
            <div className="DBInfo2">
              <div className="inDBInfo2">
                <span>
                  <img src={brief} alt="" className="Dashbrieflog" />
                </span>
              </div>
              <p>Corporate Settings</p>
            </div>
          </NavLink>
          <NavLink to="./retailbannersettings">
            <div className="DBInfo3">
              <div className="inDBInfo3">
                <span>
                  <img src={retSet} alt="" className="retSet" />
                </span>
              </div>
              <p>Retail Settings</p>
            </div>
          </NavLink>
          <div className="DBInfo4">
            <div className="inDBInfo4">
              <span>
                <img src={teacher} alt="" className="Dashbrieflog" />
              </span>
            </div>
            <p>School Settings</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
