import React, { useState, useEffect } from "react";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./corpindividualcss.css";

const CorpCourseDetail = () => {
  const { _id } = useParams();
  const [course, setCourse] = useState(null);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await axios.get(
          `https://nh-backend-nu.vercel.app/api/v1/corporate/courses/${_id}`
        );
        setCourse(response.data); // `data` contains the JSON response
      } catch (error) {
        console.error("Error fetching course:", error);
      }
    };

    fetchCourse();
  }, [_id]);

  if (!course) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Navbar />
      <div className="corp-course-details">
        <div className="individual-corp-courses">
        <div className="courseBanner">
            <div className="cousreBannerContent">
              <h3><span className="world">Worlds No.1</span><span className="institute"> ICT Training Institute</span></h3>
            </div>
          </div>
          <div className="coursesTops">
            <h1>{course.courseTitle}</h1>
            <img src={course.courseImage} alt={course.courseTitle} />
          </div>
          <div className="coursesContenting">
            <p>
              <span>Duration:</span> {course.duration}
            </p>
            <div>
              <h3>Course Overview</h3>
              <p>{course.overview}</p>
            </div>
            <div>
              <h3>Description</h3>
              <p>{course.description}</p>
            </div>
            <div>
              <h3>Syllabus</h3>
              <p>{course.syllabus}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CorpCourseDetail;
