import React from "react";
import "./mta.css";
import forArr from "../../../assets/logo/forArr.svg";
import mta1 from "../../../assets/images/mta/MTA-Security_Fundamentals.png.png";
import mta2 from "../../../assets/images/mta/How-to-Get-a-Microsoft-Technology-Associate-MTA-Networking-Certificate.png (1).png";
import mta3 from "../../../assets/images/mta/How-to-Get-a-Microsoft-Technology-Associate-MTA-Networking-Certificate.png.png";
import mta4 from "../../../assets/images/mta/MTA-Software-Development-Fundamentals-2019.png.png";

const Mta = () => {
  return (
    <section className="mtaHusk">
      <div className="mtacontainer">
        <div className="mtaimages">
          <img src={mta1} alt="" />
          <img src={mta2} alt="" />
          <img src={mta3} alt="" />
          <img src={mta4} alt="" />
        </div>
        <div className="mtawriteups">
          <h1>MTA Certifications</h1>
          <p>
            The new role-based MTA certifications establish a learning path from
            the MTA Fundamentals level to the Associate level and then to the
            Expert level. Here are the List of MTA Certifications :–
          </p>
          <button>
            <span>BOOK FOR EXAM/INQUIRY</span>
            <img src={forArr} alt="" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Mta;
