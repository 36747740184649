import React from "react";
import "./cisco.css";
import forArr from "../../../assets/logo/forArr.svg";
import cis1 from "../../../assets/images/cisco/Cisco_Specialist_600.png (1).png";
import cis2 from "../../../assets/images/cisco/ccna_600.png (1).png";
import cis3 from "../../../assets/images/cisco/ccna_600.png.png";
import cis4 from "../../../assets/images/cisco/Cisco_Specialist_600.png.png";
import avi1 from "../../../assets/images/cisco/div.eael-feature-list-icon-inner.png";

const Cisco = () => {
  return (
    <section className="ciscoHusk">
      <div className="ciscoContainer">
        <div className="ciscowriteup">
          <h1>CISCO Certification</h1>
          <p>
            CISCO certification is still worth it. Whether you would have
            already holding a Cisco certification or are considering earning
            your first, getting up to speed on the changes would help you out in
            determining where you are required to begin on the certification
            path
          </p>
          <h2>CISCO New and Popular Exams</h2>
          <ul>
            <li>
              <img src={avi1} alt="" />
              <div>
                <h1>Cisco Certified DevNtt Associate (200-901 DEVASC)</h1>
                <p>
                  This exam tests your knowledge of software development and
                  design, including: Understanding and using APIs, Cisco
                  platforms and development Application development and security
                  Infrastructure and automation.
                </p>
              </div>
            </li>
            <li>
              <img src={avi1} alt="" />
              <div>
                <h1>Cisco Certified CyberOps Associate (200-201 CBROPS)</h1>
                <p>
                  Achieving the Cisco Certified CyberOps Associate certification
                  ignites your career in cybersecurity operations. To earn the
                  CyberOps Associate certification, you pass one exam that
                  covers the fundamentals of cybersecurity operations, skills,
                  and procedures.
                </p>
              </div>
            </li>
            <li>
              <img src={avi1} alt="" />
              <div>
                <h1>
                  Developing Applications using Cisco Core Platforms and APIs
                  (DEVCOR 350-901)
                </h1>
                <p>
                  This exam tests your knowledge of software development and
                  design, including: Using APIs, Cisco platforms, Application
                  deployment and security, Infrastructure and automation
                </p>
              </div>
            </li>
          </ul>
          <button>
            <span>BOOK FOR EXAM/INQUIRY</span>
            <img src={forArr} alt="" />
          </button>
        </div>
        <img src={cis1} alt="" />
        <img src={cis2} alt="" />
        <img src={cis3} alt="" />
        <img src={cis4} alt="" />
      </div>
      <hr />
    </section>
  );
};

export default Cisco;
