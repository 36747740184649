import React from "react";
import "./comptia.css";
import forArr from "../../../assets/logo/forArr.svg";
import comp1 from "../../../assets/images/compTIA/it-fundamentals-logo.jpg.png";
import comp2 from "../../../assets/images/compTIA/CompTIA_A_2Bce.png.png";
import comp3 from "../../../assets/images/compTIA/comp-security.png.png";
import comp4 from "../../../assets/images/compTIA/comp-cloud.png.png";

const Comptia = () => {
  return (
    <section className="comtiahusk">
      <div className="comtiacontainer">
        <div className="comptiaone">
          <h1>CompTIA Certifications</h1>
          <p>
            When it comes to what you put in versus what you get out. the
            compTIA certification is most definitely worth it, find here list of
            CompTIA certifications:
          </p>
          <button>
            <span>Book for Exam Enquiry</span>
            <img src={forArr} alt="" />
          </button>
        </div>
        <div className="comptiatwo">
          <img src={comp1} alt="" />
          <img src={comp2} alt="" />
          <img src={comp3} alt="" />
          <img src={comp4} alt="" />
        </div>
      </div>
      <hr />
    </section>
  );
};

export default Comptia;
