import React from "react";
import "./schoolenquiries.css";

const FE = () => {
  return (
    <div className="shell">
      <div className="innershell">
        <div className="shell1">
          <div className="shell1top">
            <h1>
              <span className="shell1top-head">Make Enquiries</span>
            </h1>
            <p className="shell1topwords">
              Kindly fill the form below and one of our representatives will
              reach out within one business day{" "}
            </p>
          </div>
          <div className="shellbottom">
            <form action="#" method="post" className="shellform">
              <span className="name">
                <label htmlFor="fullname">Fullname:</label>
                <input type="text" id="fullname" name="fullname" required />
              </span>
              <span className="mail">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" required />
              </span>
              <span className="phone">
                <label htmlFor="phone">Phone Number:</label>
                <input type="tel" id="phone" name="phone" required />
              </span>
              <span className="messages">
                <label htmlFor="message">Message:</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  required
                ></textarea>
              </span>
            </form>
            <button type="submit">
              <span>Learn More</span>
            </button>
          </div>
        </div>
        <div className="shell2">
          <h1>
            <span>Our Centers</span>
          </h1>
          <div className="shell2contents">
            <div className="shell2top">
              <h1>
                <span>Contact Support</span>
              </h1>
              <div className="shell2top2">
                <h2>Seyi Fakoya</h2>

                <h6>seyifakoya@newhorizonsnigeria.com</h6>
              </div>
            </div>

            <div className="shell2end">
              <h1>
                <span>Talk to Sales</span>
              </h1>
              <div className="shell2end2">
                <h5>08122690471</h5>
                <h5>08122690445</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FE;
