import React, { useEffect } from "react";
import Navbar from "../../components/navbar/navbar";
import CorpBanner from "../../components/corpbanner/corpbanner";
import Footer from "../../components/footer/footer";
import FE from "../../components/enquiries/FE";
// import Banner from "../../components/banner/Banner";
import Testimonials from "../../components/studentswords/studentWords";
import Catalog from "../../components/catalogs/catalog";
import Partners from "../../components/partners/partners";
import Courses from "../../components/courses/course";

const Corporate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <CorpBanner />
      <Courses />
      <Partners />
      <Catalog />
      <Testimonials />
      <FE />
      <Footer />
    </div>
  );
};

export default Corporate;
