import React from "react";
import adminLogo from "../../../assets/logo/adminLogo.svg";
import { Link } from "react-router-dom";
import "./adminNavbar.css";

const AdminNavbar = ({ pageTitle, pageDescription }) => {
  return (
    <div className="adminNavbar">
      <Link to="/admin-Dashboard">
        <div className="adminLogo">
          <img src={adminLogo} alt="" className="adminLogoImg" />
        </div>
      </Link>
      <div className="adminNavbar2">
        <div className="adminHeading">
          <h1 className="adminNavHead">{pageTitle}</h1>
          <p>{pageDescription}</p>
        </div>
        <div className="searchAndButton">
          <input
            type="text"
            placeholder="Search here"
            className="adminSearchBar"
          />
          <button className="adminNavSearchButton">
            <span>Search</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminNavbar;
