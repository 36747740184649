import React, { useState } from "react";
import axios from "axios";
import "./addCourse.css";

const AddCourse = ({ activeComponent, isVisible }) => {
  const [submitType, setSubmitType] = useState("retail");
  const [formInputs, setFormInputs] = useState({
    courseTitle: "",
    courseCategory: "technology",
    courseImage: null,
    duration: "",
    overview: "",
    description: "",
    syllabus: "",
    link: "",
  });

  const handleFormInputChange = (e) => {
    const { name, value } = e.target;
    setFormInputs({ ...formInputs, [name]: value });
  };

  const handleFormFileChange = (e) => {
    const file = e.target.files[0];
    setFormInputs({ ...formInputs, courseImage: file });
  };

  const handleRetailButtonClick = () => {
    setSubmitType("retail");
  };

  const handleCorpButtonClick = () => {
    setSubmitType("corporate");
  };

  const handleSubmit = async (e, department) => {
    e.preventDefault();
    try {
      const formDataObject = new FormData();
      for (const key in formInputs) {
        if (key === "courseImage") {
          formDataObject.append(key, formInputs[key], formInputs[key].name);
        } else {
          formDataObject.append(key, formInputs[key]);
        }
      }

      const response = await axios.post(
        `https://nh-backend-nu.vercel.app/api/v1/${department}/courses`,
        formDataObject
      );
      console.log("Response:", response.data);
      setFormInputs({
        courseTitle: "",
        courseCategory: "technology",
        courseImage: null,
        duration: "",
        overview: "",
        description: "",
        syllabus: "",
        link: "",
      });
    } catch (error) {
      // error message
      console.error("Error:", error.message);
    }
  };

  return (
    <div
      style={{ display: isVisible ? "block" : "none" }}
      className="addCourseContainer"
    >
      <div className="state-buttons">
        <button
          onClick={handleRetailButtonClick}
          className={submitType === "retail" ? "active" : ""}
        >
          Retail
        </button>
        <button
          onClick={handleCorpButtonClick}
          className={submitType === "corporate" ? "active" : ""}
        >
          Corporate
        </button>
      </div>
      <div className="courseForm">
        <div className="courseFormLeft">
          <label htmlFor="courseTitle">Course Title</label>
          <input
            type="text"
            id="courseTitle"
            name="courseTitle"
            placeholder="Introduction to Python"
            value={formInputs.courseTitle}
            onChange={handleFormInputChange}
          />
          <label htmlFor="courseCategory"> Course Category</label>
          <select
            id="courseCategory"
            name="courseCategory"
            value={formInputs.courseCategory}
            onChange={handleFormInputChange}
          >
            <option value="technology">Technology</option>
            <option value="science">Business</option>
          </select>
          <label htmlFor="courseImage"> Course Image</label>
          <input
            type="file"
            id="courseImage"
            name="courseImage"
            // value={formInputs.courseImage}
            onChange={handleFormFileChange}
            accept=".jpg, .png"
          />
          <label htmlFor="duration">Duration</label>
          <input
            type="text"
            id="duration"
            name="duration"
            placeholder="Introduction to Python"
            value={formInputs.duration}
            onChange={handleFormInputChange}
          />
          <label htmlFor="link">Link</label>
          <input
            type="text"
            id="link"
            name="link"
            placeholder="https://example.com"
            value={formInputs.link}
            onChange={handleFormInputChange}
          />
        </div>
        <div className="courseFormRight">
          <label htmlFor="overview">Overview</label>
          <textarea
            id="overview"
            name="overview"
            placeholder="Enter Description"
            value={formInputs.overview}
            onChange={handleFormInputChange}
          />
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            placeholder="Enter Description"
            value={formInputs.description}
            onChange={handleFormInputChange}
          />
          <label htmlFor="syllabus">Syllabus</label>
          <textarea
            id="syllabus"
            name="syllabus"
            placeholder="Enter Description"
            value={formInputs.syllabus}
            onChange={handleFormInputChange}
          />
        </div>
      </div>
      <button
        className="addCourseCourseButton"
        onClick={(e) => handleSubmit(e, submitType)}
      >
        Add New Course
      </button>
    </div>
  );
};

export default AddCourse;
