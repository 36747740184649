import React, { useEffect, useState } from "react";
import "./regDat.css";
import axios from "axios";
import { IoFilterOutline } from "react-icons/io5";

const RegTable = () => {
  return (
    <div className="table-container">
      <table>
        <thead className="regTabHead">
          <tr>
            <th className="tabhead1">
              <span>Fullname</span>
              <IoFilterOutline className="sortIcon" />
            </th>
            <th className="tabhead2">
              <span>Phone No.</span> <IoFilterOutline className="sortIcon" />
            </th>
            <th className="tabhead3">
              <span>Email</span> <IoFilterOutline className="sortIcon" />
            </th>
            <th className="tabhead4">
              <span>Course</span> <IoFilterOutline className="sortIcon" />
            </th>
            <th className="tabhead5">
              <span>Status</span> <IoFilterOutline className="sortIcon" />
            </th>
            <th className="tabhead6">
              <span>Action</span> <IoFilterOutline className="sortIcon" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="tabbodyrow1">Akele</td>
            <td className="tabbodyrow2">7067501874</td>
            <td className="tabbodyrow3">diggestc@gmail.com</td>
            <td className="tabbodyrow4">CSC</td>
            <td className="tabbodyrow5">best</td>
            <td className="tabbodyrow6">dot</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RegTable;
