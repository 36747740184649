import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import tetimg from "../../assets/images/Wavy_Edu-03_Single-12-removebg-preview.png.png";
import "./tetiary.css";
import "../../components/catalogs/catalog.css";
import adeleke from "../../assets/universities logos/Adeleke-University.png";
import babcock from "../../assets/universities logos/Babcock-University.png";
import atiba from "../../assets/universities logos/Atiba-University-1.png";
import anchor from "../../assets/universities logos/Anchor-University.png";
import adegoyaga from "../../assets/universities logos/Samuel-adegboyega.png";
import wesley from "../../assets/universities logos/cropped-Wesleylogo2.png";
import bells from "../../assets/universities logos/Bells-University.png";
import benson from "../../assets/universities logos/Benson-Idahosa-University.png";
import reedeemers from "../../assets/universities logos/Redeemers-University.png";
import koladaisi from "../../assets/universities logos/Koladaisi-University.png";
import trinity from "../../assets/universities logos/Trinity-University.png";
import mountain from "../../assets/universities logos/Mountain-Top-University.png";
import christland from "../../assets/universities logos/Christland-University.png";
import leads from "../../assets/universities logos/Lead-City-University.png";
import cornerstone from "../../assets/universities logos/Precious-Cornerstone-University.png";
import mcpherson from "../../assets/universities logos/Mcpherson-University.png";
import bowen from "../../assets/universities logos/Bowen-University.png";
import caleb from "../../assets/universities logos/Caleb-University.png";
import data from "../../assets/university programs/data-analysis-1.gif";
import digitalmarketing from "../../assets/university programs/digital-marketing.png";
import autocad from "../../assets/university programs/autocad.jpg";
import networking from "../../assets/university programs/networking.jpg";
import microsoft from "../../assets/university programs/microsft technology.jpg";
import machine from "../../assets/university programs/machine learning.jpg";
import design from "../../assets/university programs/design.jpg";
import robotics from "../../assets/university programs/robotics.jpg";
import blockchain from "../../assets/university programs/blockchain.png";
import business from "../../assets/university programs/business.jpg";
import fullstack from "../../assets/university programs/fullstack.png";
import cybersecurity from "../../assets/university programs/cyber security.jpg";
import projectmanagement from "../../assets/university programs/Project-Management.png";
import ai from "../../assets/university programs/AI.png";
import datascience from "../../assets/university programs/data-science-1.png";
import software from "../../assets/university programs/software engineering.jpg";

const programs = [
  {
    image: data,
    title: "Data Analysis",
  },
  {
    image: digitalmarketing,
    title: "Digital Marketing",
  },
  {
    image: autocad,
    title: "Autocad",
  },
  {
    image: networking,
    title: "Networking",
  },
  {
    image: microsoft,
    title: "Microsoft Technology",
  },
  {
    image: machine,
    title: "Machine Learning",
  },
  {
    image: design,
    title: "Design & Multimedia",
  },
  {
    image: robotics,
    title: "Robotics",
  },
  {
    image: business,
    title: "Business Application Packages",
  },
  {
    image: blockchain,
    title: "Blockchain Development",
  },
  {
    image: projectmanagement,
    title: "Project Management",
  },
  {
    image: cybersecurity,
    title: "Cyber Security",
  },
  {
    image: ai,
    title: "Artificial Intelligence",
  },
  {
    image: fullstack,
    title: "Full-stack Web Development",
  },
  {
    image: datascience,
    title: "Data Science",
  },
  {
    image: software,
    title: "Software Engineering",
  },
];

const catalogData = [
  {
    image: adeleke,
    title: "Adeleke University",
    schoolUrl: "https://adelekeuniversity.edu.ng/",
  },
  {
    image: atiba,
    title: "Atiba Unversity",
    schoolUrl: "https://www.atibauniversity.edu.ng/",
  },
  {
    image: anchor,
    title: "Anchor University",
    schoolUrl: "https://aul.edu.ng/index.php/about-us/",
  },
  {
    image: adegoyaga,
    title: "Samuel Adeboyega University",
    schoolUrl: "https://sau.edu.ng/about-sau/",
  },
  {
    image: babcock,
    title: "Babcock University",
    schoolUrl: "https://www.babcock.edu.ng/about",
  },
  {
    image: bells,
    title: "Bells University",
    schoolUrl: "https://www.bellsuniversity.edu.ng/about-bells-university/",
  },
  {
    image: benson,
    title: "Benson Idahosa University",
    schoolUrl: "https://www.biu.edu.ng/about-biu/",
  },
  {
    image: bowen,
    title: "Bowen University",
    schoolUrl: "https://bowen.edu.ng/about-us/",
  },
  {
    image: caleb,
    title: "Caleb University",
    schoolUrl: "https://calebuniversity.edu.ng/about/",
  },
  {
    image: christland,
    title: "Christland University",
    schoolUrl: "https://www.chrislanduniversity.edu.ng/about/",
  },
  {
    image: cornerstone,
    title: "Precious Cornerstone University",
    schoolUrl: "https://pcu.edu.ng/about-us/",
  },
  {
    image: mcpherson,
    title: "Mcpherson University",
    schoolUrl: "https://www.mcu.edu.ng/home/",
  },
  {
    image: leads,
    title: "Lead City University",
    schoolUrl: "https://www.lcu.edu.ng/index.php/about-us",
  },
  {
    image: reedeemers,
    title: "Reedeemers University",
    schoolUrl: "http://cpgs.run.edu.ng/about-us/",
  },
  {
    image: mountain,
    title: "Mountain Top University",
    schoolUrl: "https://mtu.edu.ng/history-background/",
  },
  {
    image: trinity,
    title: "Trinity University",
    schoolUrl: "https://www.trinityuniversity.edu.ng/",
  },
  {
    image: koladaisi,
    title: "Koladaisi University",
    schoolUrl: "https://koladaisiuniversity.edu.ng/about-us/",
  },
  {
    image: wesley,
    title: "Wesley University",
    schoolUrl: "https://wesleyunialevels.com/",
  },
];

const Tetiary = () => {
  const [programsHeight, setProgramsHeight] = useState(0);
  const [partnersHeight, setPartnersHeight] = useState(0);

  useEffect(() => {
    const programsSection = document.querySelector(".programs");
    if (programsSection) {
      setProgramsHeight(programsSection.offsetHeight);
    }

    const partnerSection = document.querySelector(".partner");
    if (partnerSection) {
      setPartnersHeight(partnerSection.offsetHeight);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <div className="tetBanner">
        <h1>TERTIARY EDUCATION</h1>
      </div>
      <div className="intro">
        <div className="introLeft">
          <h2>INTRODUCTION</h2>
          <p>
            New Horizons Tertiary education unit equips undergraduates and
            postgraduates with International ICT and E-Business Certification
            Skills to fast-track entrepreneurial opportunities making them
            highly competent to combat the current global unemployment syndrome.
            <br />
            <br />
            The Unit equally recommends, designs, and implements/deploys
            applicable IT Solutions in form of Tech-Projects to partner
            educational institutions, with the keen aim of supporting/upgrading
            their existing IT Infrastructures.
          </p>
        </div>
        <div className="introRight">
          <img src={tetimg} alt="" className="tetimg" />
        </div>
      </div>
      <div className="programs">
        <h2 className="progTitle">Programs we Offer</h2>
        <div>
          <div className="courseContainer">
            {programs.map((item, index) => (
              <div key={index} className="courseList">
                <img src={item.image} alt={item.title} className="courseImg" />
                <div className="courseDiv">
                  <p>{item.title}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="partner">
        <h2 className="partTitle">PARTNER UNIVERSITIES/INSTITUTIONS</h2>
        <div>
          <div className="courseContainer">
            {catalogData.map((item, index) => (
              <div key={index} className="courseList">
                <a href={item.schoolUrl} target="blank">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="courseImg"
                  />
                  <div className="courseDiv">
                    <p>{item.title}</p>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="why">
        <h2>Why Choose Us?</h2>
        <div className="reasons">
          <div className="reason">
            <h4>
              Familiarity with Nigerian Local Terrain with Tested Products
            </h4>
            <p>Established in 1982 (40 years old in operations)</p>
          </div>

          <div className="reason">
            <h4>
              Familiarity with Nigerian Local Terrain with Tested Products
            </h4>
            <p>Established in 1982 (40 years old in operations)</p>
          </div>

          <div className="reason reason3">
            <h4>
              Familiarity with Nigerian Local Terrain with Tested Products
            </h4>
            <p>Established in 1982 (40 years old in operations)</p>
          </div>

          <div className="reason">
            <h4>
              Familiarity with Nigerian Local Terrain with Tested Products
            </h4>
            <p>Established in 1982 (40 years old in operations)</p>
          </div>

          <div className="reason">
            <h4>
              Familiarity with Nigerian Local Terrain with Tested Products
            </h4>
            <p>Established in 1982 (40 years old in operations)</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Tetiary;
