import React from "react";
import "./rFE.css";

const RFE = () => {
  return (
    <div className="ret-shell">
      <div className="ret-innershell">
        <div className="ret-shell1">
          <div className="ret-shell1top">
            <h1>
              <span className="ret-shell1top-head">Make Enquiries</span>
            </h1>
            <p className="ret-shell1topwords">
              Kindly fill the form below and one of our representatives will
              reach out within one business day{" "}
            </p>
          </div>
          <div className="ret-shellbottom">
            <form action="#" method="post" className="shellform">
              <span className="name">
                <label htmlFor="fullname">Fullname:</label>
                <input type="text" id="fullname" name="fullname" required />
              </span>
              <span className="mail">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" required />
              </span>
              <span className="phone">
                <label htmlFor="phone">Phone Number:</label>
                <input type="tel" id="phone" name="phone" required />
              </span>
              <span className="messages">
                <label htmlFor="message">Message:</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  required
                ></textarea>
              </span>
            </form>
            <button type="submit">
              <span>Learn More</span>
            </button>
          </div>
        </div>
        <div className="ret-shell2">
          <h1>
            <span>Our Centers</span>
          </h1>
          <div className="ret-shell2contents">
            <div className="ret-shell2top">
              <h1>
                <span>Lagos Center</span>
              </h1>
              <div className="ret-shell2top2">
                <h2>Ikeja</h2>
                {/* <h5>(+234) 8122690414</h5> */}
                <h6>No 5 Babatola Street, Ikeja, Lagos State</h6>
              </div>
            </div>
            <div className="ret-shell2mid">
              <h1>
                <span>Abuja Center</span>
              </h1>
              <div className="ret-shell2mid2">
                <h2>Basement & 3rd Floor,</h2>
                <h5>Nigerian Reinsurance Building, Beside</h5>
                <h6>Unity Bank HQ, Herbert Macauly Way, CBD,, Abuja</h6>
              </div>
            </div>
            <div className="ret-shell2end">
              <h1>
                <span>Port Harcourt Center</span>
              </h1>
              <div className="ret-shell2end2">
                <h2>274, Aba road, </h2>
                <h5>Hycmo house, by Ekere junction.</h5>
                <h6>Before 1st Artillery</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RFE;
