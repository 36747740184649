import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./coursesretail.css";

const CoursesRetail = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  const api_url = "https://nh-backend-nu.vercel.app/api/v1/retail/courses";

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(api_url);
        const data = await response.json();

        if (response.ok) {
          // Randomize the data and take only 8 unique courses
          const randomCourses = data
            .sort(() => Math.random() - 0.5) // Shuffle array
            .slice(0, 8); // Take the first 8

          setCourses(randomCourses);
        } else {
          console.error("Error fetching courses:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      } finally {
        setLoading(false); // Always turn off loading after fetching
      }
    };

    fetchCourses();
  }, []);

  return (
    <div className="coursesRetailContainer">
      <div className="courseRetailshell">
        <div className="courseRetailTop">
          <h1 className="ctopRetailheading">
            <span>Popular Courses you can take</span>
          </h1>
          <p className="cmidRetailheading">
            <span>Trending</span>
          </p>
        </div>
        <div className="popCourseRetailContainer">
          {loading ? (
            <p>Loading...</p>
          ) : (
            courses.map((item, index) => (
              <Link key={index} to={`/retail-courses/${item._id}`}>
                <div key={index} className="popCourseRetailList">
                  <img
                    src={item.courseImage}
                    alt={item.courseTitle}
                    className="popCourseRetailImg"
                  />
                  <div className="popCourseRetailDiv">
                    <p>{item.courseTitle}</p>
                  </div>
                </div>
              </Link>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default CoursesRetail;
