import React from "react";
import "./componentTitle.css";
import beef from "../../../assets/images/beef.png";

const ComponentTitle = () => {
  return (
    <section className="cTitleContainer">
      <div className="cTitleHusk">
        <div className="cTitleTop">
          <img src={beef} alt="a black horizontal line" />
          <h1>Popular Certification Exams</h1>
        </div>
      </div>
      <hr className="horizontalLine" />
    </section>
  );
};

export default ComponentTitle;
