import React, { useState } from "react";
import "./navbar.css";
import { Link, useLocation } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import logo from "../../assets/logo/Layer_1_light.svg";
import NavHam from "../../assets/images/menu.png";
import closeHam from "../../assets/images/close.png";

const Navbar = () => {
  const [dropDown, setDropDown] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const isActiveRoute = (route) => {
    return location.pathname === route;
  };

  const toggleMenu = () => {
    setDropDown(!dropDown);
  };

  const toggleMobileMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const showMenu = () => {
    setDropDown(true);
  };
  const hideMenu = () => {
    setDropDown(false);
  };
  return (
    <div className="navbar">
      <div className="container">
        <Link to="/">
          <img src={logo} alt="NewHorzions Logo" className="nhLogo" />
        </Link>
        <div className="sided">
          <img
            src={menuOpen ? closeHam : NavHam}
            alt="Menu"
            className="hamburger"
            onClick={toggleMobileMenu}
          />
          <ul className={`liContainer ${menuOpen ? "show" : ""}`}>
            <Link to="/">
              <li
                className={`homebutton ${isActiveRoute("/") ? "active" : ""}`}
              >
                <span className="home">Home</span>
              </li>
            </Link>
            <li
              className="dropdown"
              onMouseEnter={showMenu}
              onMouseLeave={hideMenu}
            >
              <span className="train" to="./trainings">
                Training
                {dropDown && (
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/corporate" className="drpln">
                        Corporate Services
                      </Link>
                    </li>
                    <li>
                      <Link to="/retail" className="drpln">
                        Retail Services
                      </Link>
                    </li>
                    <li>
                      <Link to="/school" className="drpln">
                        School Hub
                      </Link>
                    </li>
                    <li>
                      <Link to="/tetiary" className="drpln">
                        Tertiary Education
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://virtuallive.newhorizonsnigeria.com.ng/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Virtual Live
                      </Link>
                    </li>
                  </ul>
                )}
              </span>
              <FaChevronDown className="dropArrow" onClick={toggleMenu} />
            </li>
            <Link to="/Certification">
              <li
                className={`cert ${
                  isActiveRoute("/Certification") ? "active" : ""
                }`}
              >
                <span className="cert" to="./certifications">
                  Certification
                </span>
              </li>
            </Link>
            <Link to="/contactus">
              <li
                className={`contacts ${
                  isActiveRoute("/contactus") ? "active" : ""
                }`}
              >
                <span className="contact" to="./contact">
                  Contact Us
                </span>
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
